
import {
  ProfileModelsProfileModel,
  TwelveMonthTyreInsuranceModelsCountryModel,
  TwelveMonthTyreInsuranceModelsCustomerModel,
  TwelveMonthTyreInsuranceModelsDealerListModel,
  TwelveMonthTyreInsuranceModelsDealerModel,
  TwelveMonthTyreInsuranceModelsPolicyModel,
  TwelveMonthTyreInsuranceModelsTyreInformationModel,
  TwelveMonthTyreInsuranceModelsTyreModel,
  TwelveMonthTyreInsuranceModelsTyrePatternListModel,
  TwelveMonthTyreInsuranceModelsTyreRangeListModel,
  TwelveMonthTyreInsuranceModelsTyreSizeModel,
  TwelveMonthTyreInsuranceModelsVehicleModel,
  TwelveMonthTyreInsuranceSubmitPolicyViewModel,
  VehiclesModelsVehicleModel,
} from "@/api-client";
import { TwelveMonthTyreInsurance, Vehicles } from "@/network/api";
import { Options, Vue } from "vue-class-component";
import ViewMileageLogs from "./ViewMileageLogs.vue";
import AddMileageLog from "./AddMileageLog.vue";
import ViewTyres from "./ViewTyres.vue";
import Spinner from "./Spinner.vue";
import SimpleTypeahead from "./SimpleTypeahead.vue";
import UploadImage from "./UploadImage.vue";
import router from "@/router";
import DeleteConfirmationModal from "@/components/DeleteConfirmationModal.vue";
import SubmissionErrorModal from "@/components/SubmissionErrorModal.vue";
import { store } from "@/store";

@Options({
  components: {
    ViewMileageLogs,
    AddMileageLog,
    ViewTyres,
    DeleteConfirmationModal,
    SubmissionErrorModal,
    Spinner,
    UploadImage,
    SimpleTypeahead,
  },
  props: {
    policyId: String,
    newPolicy: Boolean,
    isMobile: Boolean,
    triggerNewPolicy: Number,
  },
  emits: ["updateDetails", "deletedVehicle", "clearFields"],
  computed: {
    isEditable() {
      return this.edit || this.isMobile;
    },
  },
})
export default class ViewInsuranceDetails extends Vue {
  policyId?: string;

  user: ProfileModelsProfileModel = { FirstName: "", Email: "" };

  newPolicy?: boolean;
  edit = false;
  triggerNewPolicy = 0;
  isMobile?: boolean;

  titles: Array<string> = ["Mr", "Mrs", "Ms", "Other"];
  countries: Array<TwelveMonthTyreInsuranceModelsCountryModel> = [];
  dealers: Array<TwelveMonthTyreInsuranceModelsDealerListModel> = [];
  vehicles: Array<VehiclesModelsVehicleModel> = [];
  tyreRanges: Array<TwelveMonthTyreInsuranceModelsTyreRangeListModel> = [];
  tyrePatterns: Array<TwelveMonthTyreInsuranceModelsTyrePatternListModel> = [];
  tyreSizes: Array<TwelveMonthTyreInsuranceModelsTyreSizeModel> = [];

  isNewVehicle = false;
  newVehicleName?: string | null = null;

  vehicle?: VehiclesModelsVehicleModel | null = null;
  numberOfTyres = 4;

  visibleSpinner = false;

  termsAndDetailsConfirmed = false;
  purchaseDate?: string | null = null;
  customer: TwelveMonthTyreInsuranceModelsCustomerModel = {
    Title: this.titles[0],
  };
  dealer: TwelveMonthTyreInsuranceModelsDealerModel = {};

  tyreRange?: TwelveMonthTyreInsuranceModelsTyreRangeListModel = {};
  tyrePattern?: TwelveMonthTyreInsuranceModelsTyrePatternListModel = {};
  tyreSize?: TwelveMonthTyreInsuranceModelsTyreSizeModel = {};
  tyreInformation: TwelveMonthTyreInsuranceModelsTyreInformationModel = {};
  insuranceVehicle: TwelveMonthTyreInsuranceModelsVehicleModel = {};
  tyres: Array<TwelveMonthTyreInsuranceModelsTyreModel> = [
    { SerialNumber: undefined, ManufactureDate: undefined },
    { SerialNumber: undefined, ManufactureDate: undefined },
    { SerialNumber: undefined, ManufactureDate: undefined },
    { SerialNumber: undefined, ManufactureDate: undefined },
  ];
  country: TwelveMonthTyreInsuranceModelsCountryModel = {
    Id: 16,
    Name: "South Africa",
  };

  policy: TwelveMonthTyreInsuranceModelsPolicyModel = {
    Id: undefined,
    Dealer: this.dealer,
    Customer: this.customer,
    TyreInformation: this.tyreInformation,
    Vehicle: this.insuranceVehicle,
    Tyres: this.tyres,
    Country: this.country,
    Status: {},
    TotalPurchasePriceExcludingVat: undefined,
    DateOfPurchase: undefined,
    PolicyNumber: undefined,
    InvoiceNumber: undefined,
    UploadedInvoiceAbsolutePath: undefined,
    TermsAndConditionsAccepted: false,
    CustomerAcceptsEnteredDetails: false,
  };

  policyUpload: TwelveMonthTyreInsuranceSubmitPolicyViewModel = {
    Dealer: this.dealer,
    Customer: this.customer,
    TyreInformation: this.tyreInformation,
    Vehicle: this.insuranceVehicle,
    Tyres: this.tyres,
    Country: this.country,
    Status: {},
    TotalPurchasePriceExcludingVat: undefined,
    DateOfPurchase: undefined,
    PolicyNumber: undefined,
    InvoiceNumber: undefined,
    UploadedInvoiceRelativePath: undefined,
    TermsAndConditionsAccepted: false,
    CustomerAcceptsEnteredDetails: false,
  };

  get parseDate() {
    return new Date(this.policy?.DateOfPurchase!);
  }

  created() {
    this.getCountries();
    this.getVehicles();
    this.getRanges();

    this.user = store.getters["user/profile"] as ProfileModelsProfileModel;

    this.customer.FirstName = this.user.FirstName;
    this.customer.LastName = this.user.LastName;
    this.customer.EmailAddress = this.user.Email;
    this.customer.CellPhoneNumber = this.user.ContactNumber;
    this.customer.EmailAddress = this.user.Email;
    this.$watch("triggerNewPolicy", () => {
      if (this.newPolicy) {
        this.edit = true;
        this.clearFields();
      }
    });

    this.$watch("policyId", () => {
      this.edit = false;
      this.getPolicy();
    });
  }

  purchaseDateChanged(dateChangeEvent: InputEvent) {
    this.policy.DateOfPurchase =
      (dateChangeEvent.target as HTMLInputElement).value + "T00:00:00";
  }

  updateImagePath(path: string) {
    this.policyUpload.UploadedInvoiceRelativePath = path;
  }

  onCountrySelect() {
    this.getDealers();
  }

  onRangeSelect() {
    this.getPatterns();
  }

  onPatternSelect() {
    this.getSizes();
  }

  onQtySelect() {
    switch (this.numberOfTyres) {
      case 1:
        this.tyres = [{ SerialNumber: undefined, ManufactureDate: undefined }];
        break;
      case 2:
        this.tyres = [
          { SerialNumber: undefined, ManufactureDate: undefined },
          { SerialNumber: undefined, ManufactureDate: undefined },
        ];
        break;
      case 3:
        this.tyres = [
          { SerialNumber: undefined, ManufactureDate: undefined },
          { SerialNumber: undefined, ManufactureDate: undefined },
          { SerialNumber: undefined, ManufactureDate: undefined },
        ];
        break;
      case 4:
        this.tyres = [
          { SerialNumber: undefined, ManufactureDate: undefined },
          { SerialNumber: undefined, ManufactureDate: undefined },
          { SerialNumber: undefined, ManufactureDate: undefined },
          { SerialNumber: undefined, ManufactureDate: undefined },
        ];
        break;
      case 5:
        this.tyres = [
          { SerialNumber: undefined, ManufactureDate: undefined },
          { SerialNumber: undefined, ManufactureDate: undefined },
          { SerialNumber: undefined, ManufactureDate: undefined },
          { SerialNumber: undefined, ManufactureDate: undefined },
          { SerialNumber: undefined, ManufactureDate: undefined },
        ];
        break;
      default:
        this.tyres = [
          { SerialNumber: undefined, ManufactureDate: undefined },
          { SerialNumber: undefined, ManufactureDate: undefined },
          { SerialNumber: undefined, ManufactureDate: undefined },
          { SerialNumber: undefined, ManufactureDate: undefined },
        ];
        break;
    }
  }

  onDealerSelect(item: TwelveMonthTyreInsuranceModelsDealerListModel) {
    this.dealer.Id = item.Id;
  }

  onDealerInput(item: any) {
    if (item?.input != undefined && item?.input.length > 0) {
      var id = this.validateSelectedDealer(item?.input);
      if (id == undefined || id < 1) {
        this.dealer.Id = undefined;
      } else {
        this.dealer.Id = id;
      }
    }
  }

  getPolicy() {
    this.clearContainerMessages();
    if (this.policyId) {
      this.visibleSpinner = true;
      setTimeout(() => {
        TwelveMonthTyreInsurance.apiPrivateTwelvemonthtyreinsuranceGetPolicyGet(
          this.policyId
        )
          .then((res) => {
            this.policy = res.data;

            this.purchaseDate = this.policy.DateOfPurchase!.split("T")[0];

            this.numberOfTyres = this.policy.Tyres?.length ?? 1;
            this.customer = this.policy.Customer ?? {};
            this.dealer = this.policy.Dealer ?? {};

            this.vehicle = this.vehicles.find(
              (v) =>
                v.Id?.toLowerCase() ==
                this.policy.Vehicle?.MyTyresVehicleId?.toLowerCase()
            );
            this.insuranceVehicle = this.policy.Vehicle ?? {};
            this.tyreRange = this.tyreRanges.find(
              (r) =>
                r.Name?.toLowerCase() ==
                this.policy.TyreInformation?.Range?.toLowerCase()
            );
            this.tyrePattern = this.tyrePatterns.find(
              (p) =>
                p.Name?.toLowerCase() ==
                this.policy.TyreInformation?.Pattern?.toLowerCase()
            );
            this.tyreSize = this.tyreSizes.find(
              (s) =>
                s.Name?.toLowerCase() ==
                this.policy.TyreInformation?.Size?.toLowerCase()
            );
            this.tyreInformation = this.policy.TyreInformation ?? {};
            this.tyres = this.policy.Tyres ?? [
              { SerialNumber: undefined, ManufactureDate: undefined },
            ];
            this.country = this.policy.Country ?? {};
          })
          .catch((error) => {
            console.log(error);
          });
        this.visibleSpinner = false;
      }, 500);
    }
  }

  cancelChanges() {
    this.$emit("clearFields");
    this.edit = false;
    if (this.newPolicy) {
      this.clearFields();
    } else {
      this.getPolicy;
    }
  }

  clearContainerMessages() {
    try {
      var saveErrors = document.getElementById(
        "policySaveValidationErrorsContainer"
      );
      var submitErrors = document.getElementById(
        "policySubmissionMessagesContainer"
      );
      if (saveErrors != null && saveErrors != undefined) {
        saveErrors.textContent = "";
      }
      if (submitErrors != null && submitErrors != undefined) {
        submitErrors.textContent = "";
      }
    } catch (errors) {
      console.log(errors);
    }
  }

  clearFields() {
    this.vehicle = null;
    this.isNewVehicle = false;
    this.newVehicleName = null;

    this.numberOfTyres = 4;

    this.termsAndDetailsConfirmed = false;
    this.purchaseDate = null;
    this.customer = {
      Title: this.titles[0],
    };
    this.dealer = {};

    this.tyreRange = {};
    this.tyrePattern = {};
    this.tyreSize = {};
    this.tyreInformation = {};
    this.insuranceVehicle = {};
    this.tyres = [
      { SerialNumber: undefined, ManufactureDate: undefined },
      { SerialNumber: undefined, ManufactureDate: undefined },
      { SerialNumber: undefined, ManufactureDate: undefined },
      { SerialNumber: undefined, ManufactureDate: undefined },
    ];
    this.country = {
      Id: 16,
      Name: "South Africa",
    };

    this.policy = {
      Id: undefined,
      Dealer: this.dealer,
      Customer: this.customer,
      TyreInformation: this.tyreInformation,
      Vehicle: this.insuranceVehicle,
      Tyres: this.tyres,
      Country: this.country,
      Status: {},
      TotalPurchasePriceExcludingVat: undefined,
      DateOfPurchase: undefined,
      PolicyNumber: undefined,
      InvoiceNumber: undefined,
      UploadedInvoiceAbsolutePath: undefined,
      TermsAndConditionsAccepted: false,
      CustomerAcceptsEnteredDetails: false,
    };

    this.policyUpload = {
      Dealer: this.dealer,
      Customer: this.customer,
      TyreInformation: this.tyreInformation,
      Vehicle: this.insuranceVehicle,
      Tyres: this.tyres,
      Country: this.country,
      Status: {},
      TotalPurchasePriceExcludingVat: undefined,
      DateOfPurchase: undefined,
      PolicyNumber: undefined,
      InvoiceNumber: undefined,
      UploadedInvoiceRelativePath: undefined,
      TermsAndConditionsAccepted: false,
      CustomerAcceptsEnteredDetails: false,
    };
  }

  validateInput(policy: TwelveMonthTyreInsuranceSubmitPolicyViewModel) {
    var errorContainer = document.getElementById(
      "policySaveValidationErrorsContainer"
    );
    if (errorContainer != null && errorContainer != undefined) {
      errorContainer.textContent = "";
    }
    var errors = [];
    if (this.user?.Alias == undefined || this.user?.Alias == "") {
      errors.push("Please enter a valid value for Name");
    }
    if (
      policy.Customer?.CellPhoneNumber == undefined ||
      policy.Customer?.CellPhoneNumber == ""
    ) {
      errors.push("Please enter a valid value for Contact Number");
    }
    if (
      policy.Customer?.IdOrPassportNumber == undefined ||
      policy.Customer?.IdOrPassportNumber == ""
    ) {
      errors.push("Please enter a valid value for ID or Passport Number");
    }
    if (policy.Dealer?.Id == undefined || policy.Dealer?.Id < 1) {
      errors.push("Please select a Dealer from the list of provided Dealers");
    }
    if (policy.Vehicle?.Name == undefined || policy.Vehicle?.Name == "") {
      errors.push("Please specify a valid Vehicle");
    }
    if (
      this.purchaseDate == undefined ||
      this.purchaseDate == null ||
      this.purchaseDate == ""
    ) {
      errors.push("Please enter a valid Date of Purchase");
    }
    if (policy.InvoiceNumber == undefined || policy.InvoiceNumber == "") {
      errors.push("Please enter a valid Invoice Number");
    }
    if (
      policy.TotalPurchasePriceExcludingVat == undefined ||
      policy.TotalPurchasePriceExcludingVat <= 0
    ) {
      errors.push("Please enter a valid for the Total Purchase Price");
    }
    if (
      policy.UploadedInvoiceRelativePath == undefined ||
      policy.UploadedInvoiceRelativePath == ""
    ) {
      errors.push("Please upload a valid Invoice");
    }
    if (
      policy.TyreInformation?.Range == undefined ||
      policy.TyreInformation?.Range == ""
    ) {
      errors.push("Please select a valid Tyre Range");
    }
    if (policy.Tyres != undefined && policy.Tyres.length != undefined) {
      for (var i = 0; i < policy.Tyres?.length; i++) {
        if (
          policy.Tyres[i]?.SerialNumber == undefined ||
          policy.Tyres[i]?.SerialNumber == "" ||
          policy.Tyres[i]?.ManufactureDate == undefined ||
          policy.Tyres[i]?.ManufactureDate == ""
        ) {
          errors.push(
            "Please enter the DOT Number and Manufacture Date for all " +
              policy.Tyres?.length +
              " tyre(s)"
          );
          break;
        }
      }
    }
    if (
      policy.TermsAndConditionsAccepted == undefined ||
      policy.TermsAndConditionsAccepted == false
    ) {
      errors.push("Please accepts the Terms and Conditions to save the policy");
    }
    
    if (!this.dealer.CounterhandName || this.dealer.CounterhandName == "") {
      errors.push("Please enter a Counter Hand Name");
    }

    if (!this.dealer.CounterHandCellNumber || this.dealer.CounterHandCellNumber == "") {
      errors.push("Please enter a Counter Cell Number");
    }

    if (errors.length > 0) {
      for (var i = 0; i < errors.length; i++) {
        var p = document.createElement("p");
        p.style.color = "red";
        p.textContent = errors[i];
        errorContainer?.append(p);
      }
      return false;
    }
    return true;
  }

  validateSelectedDealer(dealerName: string) {
    for (var i = 0; i < this.dealers.length; i++) {
      if (this.dealers[i].Name === dealerName) {
        return this.dealers[i].Id;
      }
    }
    return -1;
  }

  savePolicy() {
    this.tyreInformation = {
      Range: this.tyreRange?.Id?.toLowerCase(),
      Pattern: this.tyrePattern?.Name,
      Size: this.tyreSize?.Name,
      Sku: this.tyreSize?.Sku?.Name,
    };

    // If Else-If is for the ability to switch between input from droplist/ textfield
    if (this.isNewVehicle == true) {
      // User entering new vehicle - use tempVehicle object
      const createTempNewVehicle: VehiclesModelsVehicleModel =
        this.createNewVehicleObject();

      this.insuranceVehicle = {
        Name: createTempNewVehicle?.Name,
        RegistrationNumber: undefined,
        MyTyresVehicleId: undefined,
      };
    } else if (this.isNewVehicle == false) {
      // User selecting an existing vehicle
      this.insuranceVehicle = {
        Name: this.vehicle?.Name,
        RegistrationNumber: this.vehicle?.LicenseNumber,
        MyTyresVehicleId: this.vehicle?.Id,
      };
    }

    if (
      this.customer.EmailAddress == "" ||
      this.customer.EmailAddress == undefined ||
      this.customer.EmailAddress == null
    ) {
      this.customer.EmailAddress = this.user.Email;
    }

    this.policyUpload = {
      Dealer: this.dealer,
      Customer: this.customer,
      TyreInformation: this.tyreInformation,
      Vehicle: this.insuranceVehicle,
      Tyres: this.tyres,
      Country: this.country,
      Status: {},
      TotalPurchasePriceExcludingVat:
        this.policy.TotalPurchasePriceExcludingVat,
      DateOfPurchase: this.purchaseDate + "T00:00:00",
      InvoiceNumber: this.policy.InvoiceNumber,
      UploadedInvoiceRelativePath:
        this.policyUpload.UploadedInvoiceRelativePath,
      TermsAndConditionsAccepted: this.termsAndDetailsConfirmed,
      CustomerAcceptsEnteredDetails: this.termsAndDetailsConfirmed,
      IsMyDunlopDashboardRequest: true,
    };

    var apiDiv = document.getElementById("policySubmissionMessagesContainer");
    if (apiDiv != null && apiDiv != undefined) {
      apiDiv.textContent = "";
    }

    if (!this.validateInput(this.policyUpload)) {
      return;
    }

    if (this.newPolicy) {
      this.visibleSpinner = true;

      let createdPolicy = {
        ...this.policyUpload,
      } as TwelveMonthTyreInsuranceSubmitPolicyViewModel;
      TwelveMonthTyreInsurance.apiPrivateTwelvemonthtyreinsuranceSubmitPolicyPost(
        createdPolicy
      )
        .then((res) => {
          this.visibleSpinner = false;
          console.log(res);
          if (res) {
            this.cancelChanges();
            if (this.isMobile) {
              router.push({ name: "DunlopSure" });
            }
            this.$emit("updateDetails");
          } else {
            document.getElementById("triggerSubmissonModal")!.click();
          }
          if (apiDiv != undefined) {
            var p = document.createElement("p");
            p.textContent =
              "Succesfully created policy: " + res.data.PolicyNumber;
            p.style.fontWeight = "bold";
            apiDiv?.append(p);
          }
        })
        .catch((error) => {
          this.visibleSpinner = false;
          console.error(error);
          if (apiDiv != undefined) {
            var p = document.createElement("p");
            p.textContent = "Failed to create policy: " + error.response?.data;
            p.style.fontWeight = "bold";
            apiDiv?.append(p);
          }
        });
    } else {
      if (this.policy?.Status?.Name == "Incomplete") {
        //erm??
      }
    }
    this.$emit("clearFields");
  }

  getCountries() {
    this.visibleSpinner = true;
    TwelveMonthTyreInsurance.apiPrivateTwelvemonthtyreinsuranceGetCountriesGet()
      .then((res) => {
        this.countries =
          res.data as Array<TwelveMonthTyreInsuranceModelsCountryModel>;
        this.visibleSpinner = false;
        console.log(this.countries, "countries");
        this.getDealers();
      })
      .catch((error) => {
        console.log(error);
        this.visibleSpinner = false;
      });
  }

  getDealers() {
    this.visibleSpinner = true;
    TwelveMonthTyreInsurance.apiPrivateTwelvemonthtyreinsuranceGetDealersGet(
      this.country.Id
    )
      .then((res) => {
        this.dealers =
          res.data as Array<TwelveMonthTyreInsuranceModelsDealerListModel>;
        this.visibleSpinner = false;
        console.log(this.dealers, "dealers");
      })
      .catch((error) => {
        console.log(error);
        this.visibleSpinner = false;
      });
  }

  getVehicles() {
    this.visibleSpinner = true;
    Vehicles.apiPrivateVehiclesGet()
      .then((res) => {
        this.visibleSpinner = false;
        this.vehicles = res.data.Items as Array<VehiclesModelsVehicleModel>;
        this.vehicle = this.vehicles[0] ?? null;
        this.insuranceVehicle = {
          Name: this.vehicle.Name,
          RegistrationNumber: this.vehicle.LicenseNumber,
          MyTyresVehicleId: this.vehicle.Id,
        };
        console.log(this.vehicles, "vehicles");
      })
      .catch((error) => {
        this.visibleSpinner = false;
        console.log(error);
      });
  }

  getRanges() {
    this.visibleSpinner = true;
    TwelveMonthTyreInsurance.apiPrivateTwelvemonthtyreinsuranceGetTyreRangesGet()
      .then((res) => {
        this.tyreRanges =
          res.data as Array<TwelveMonthTyreInsuranceModelsTyreRangeListModel>;
        this.tyreRange = this.tyreRanges[0] ?? null;
        console.log(this.tyreRanges, "ranges");
        this.getPatterns();
      })
      .catch((error) => {
        this.visibleSpinner = false;
        console.log(error);
      });
  }

  getPatterns() {
    this.visibleSpinner = true;
    TwelveMonthTyreInsurance.apiPrivateTwelvemonthtyreinsuranceGetTyrePatternsGet(
      this.tyreRange?.Id
    )
      .then((res) => {
        this.tyrePatterns =
          res.data as Array<TwelveMonthTyreInsuranceModelsTyrePatternListModel>;
        this.tyrePattern = this.tyrePatterns[0] ?? null;
        console.log(this.tyrePatterns, "patterns");
        this.getSizes();
      })
      .catch((error) => {
        this.visibleSpinner = false;
        console.log(error);
      });
  }

  getSizes() {
    this.visibleSpinner = true;
    TwelveMonthTyreInsurance.apiPrivateTwelvemonthtyreinsuranceGetTyreSizesGet(
      this.tyreRange?.Id,
      this.tyrePattern?.Id
    )
      .then((res) => {
        this.visibleSpinner = false;
        this.tyreSizes =
          res.data as Array<TwelveMonthTyreInsuranceModelsTyreSizeModel>;
        this.tyreSize = this.tyreSizes[0] ?? null;
        console.log(this.tyreSizes, "sizes");
      })
      .catch((error) => {
        this.visibleSpinner = false;
        console.log(error);
      });
  }

  clearVehicleInput() {
    //Switching between vehicle list and text field - clear any previoulsy stored values
    if (this.isNewVehicle == true) this.vehicle = null;
    else if (this.isNewVehicle == false) this.newVehicleName = null;
  }

  createNewVehicleObject(): VehiclesModelsVehicleModel {
    // User opted to type in a vehicle name instead of select an existing vehicle - instantiate temporary vehicle object

    const tempNewVehicle: VehiclesModelsVehicleModel = {
      //Required Field (Name) - set to Vehicle Name from text field or "Name_Temp" if null
      Name:
        this.newVehicleName !== null && this.newVehicleName !== undefined
          ? this.newVehicleName
          : "Name_Temp",
      //Required Field (LicenseNumber) - Set to "LicenseNumber_Temp"
      LicenseNumber: "LicenseNumber_Temp",
      VinNumber: undefined,
      //Required Field (ExpiryDate) - Set to the current date
      ExpiryDate: new Date().toISOString(),
      NextReminderDate: undefined,
      ImageRelativeUrl: undefined,
      Make: undefined,
      MakeId: undefined,
      Model: undefined,
      ModelId: undefined,
      Specification: undefined,
      SpecificationId: undefined,
      EstimatedMonthlyMileage: undefined,
      Colour: undefined,
      VehicleType: undefined,
      EngineNumber: undefined,
      Tare: undefined,
    };

    // Log the created object (you can send it to an API or use it as needed)
    console.log(tempNewVehicle);

    // You can return the tempNewVehicle object if needed
    return tempNewVehicle;
  }
}
